import styled, { keyframes } from 'styled-components';

const toBottom = keyframes`
  100% {
    translate: 0 0%;
  }
`;

const PaymentSuccessfulOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({theme}) => theme.background};
  height: var(--dvh); /* fallback */
  height: 100svh;
  padding: 0 18px 0 18px;
  gap: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  
  & > div {
    align-self: center;
    justify-content: center;
  }

  & > div:nth-child(1) {
    width: 100%;
    translate: 0 -100%;
    animation: ${toBottom} 0.8s 1.5s ease-in-out forwards;
  }
`;

const OrderAndPayCounterSuccessful = styled.div<SuccessfulProps>`
  background: ${props => props.functionality === 'order_and_collect' || props.functionality === 'order_at_table' ? "#FFFFFF" : ""};
  box-shadow: ${props => props.functionality === 'order_and_collect' || props.functionality === 'order_at_table' ? "0px 8px 20px rgba(149, 157, 165, 0.1)" : ""};
  border-radius: ${props => props.functionality === 'order_and_collect' || props.functionality === 'order_at_table' ? "5px" : ""};
  margin:  ${props => props.functionality === 'order_and_collect' || props.functionality === 'order_at_table' ? "20px 10px" : ""};
  border: ${props => props.functionality === 'order_and_collect' || props.functionality === 'order_at_table' ? "1px solid rgba(64, 64, 64, 0.15)" : "none"};
`;

const RestaurantNameContainer = styled.div`
  margin: auto;
  line-height: 14px;
`;

const SuccessfullIconWrapper = styled.div<SuccessfulProps>`
  display: flex;
  align-items: flex-start;
  justify-content: ${props => props.functionality !== 'order_and_collect' && props.functionality !== 'order_at_table' ? 'left' : 'center'};
  background-color: ${({theme}) => theme.gray_30};
  gap: 8px;

  img {
  width: 80px;
  height: 56px;
  object-fit: contain;
  background-color: ${({theme}) => theme.secondary};
  border-radius: ${({theme}) => theme.radius_sm};
  }
`;

const SuccessfullSentenceWrapper = styled.div`
  margin: 0 auto;
`;

const TableStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
  flex-wrap: wrap;
  max-width: 100%;
`;

const DivCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background-color: ${({theme}) => theme.ticket_header_bg};
  color: ${({theme}) => theme.text};
  border-radius: ${({theme}) => theme.radius_sm};
  padding: 8px;
  margin: 8px;

  b {
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    text-align: center;
  }
`;

const IDGroupContainer = styled.div`
  background: ${({theme}) => theme.cash_10};
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin-left: 20px;
`;

const PendingToPayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  flex: 1;
  max-height: 1em;
  margin-bottom: 4px;

  hr {
    align-self: flex-end;
    flex: 1;
    border: none;
    border-bottom: 3px dotted ${({theme}) => theme.gray_40};
    margin: 0 8px 0 0;
  }
`;

type PaymentListProps = {
  maxHeight?: boolean;
}

type SuccessfulProps = {
  functionality?: string;
}

const PaymentsList = styled.div<PaymentListProps>`
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  max-height: ${props => props.maxHeight ? '50px' : '100%'};
  overflow: hidden;
`;

const PaymentDone = styled.div`
  display: flex;
  border-width: 2px; 
  background: #EFF8F4;
  padding: 4px 8px;
  font-size: ${props => props.theme.font_xs};
  margin-right: 8px;
  margin-bottom: 8px;
  align-items: center;
  font-weight: 500;
`;

const LoginBoxReminder = styled.div`
  display: flex;
  background: ${props => props.theme.text};
`;

const LoginMessageWrapper = styled.div`
  padding: 16px;
  line-height: 16px;
  width: 66.67%;
`;

const LoginButtonWrapper = styled.div`
  padding: 8px;
  margin: auto 0;
  width: 33.33%;
`;

const LoginButton = styled.div`
  border-radius: 9999px;
  padding: 4px;
  background: white;
  text-align: center;
  margin-right: 4px;
`;

const PaymentSuccessfulText = styled.div<SuccessfulProps>`
  margin: 0 auto;
  line-height: 18px;
  text-align: ${props => props.functionality !== 'order_and_collect' ? 'left' : 'center'};
`;

const ShowButton = styled.button`
  font-size: 12px;
  font-family: ${({theme}) => theme.typography};
  border: 2px solid #404040;
  border-radius: 33px;
  background: #FFF;
  padding: 5px 10px;
`;

type Banner = {
  isLogged: boolean;
}

const PointsBannerContainer = styled.div<Banner>`
  background-color: ${({isLogged}) => isLogged ? '#EFF8F4' : '#FCEBE7'};
  border-radius: 20px;
  padding: 24px 30px;
  
  h3 {
    color: ${({isLogged}) => isLogged ? '#5FB894' : '#662B26'};
    font-size: 1.25rem;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    color: ${({isLogged}) => isLogged ? '#404040' : '#662B26'};
    font-size: 0.875rem;
    text-align: center;
    margin: 18px 0;
  }
`;

const ReviewContainer = styled.div`
  background-color: #FEF2CE;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: solid 1px #DDD;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%{
    transform:scale(0.7);
    opacity: 0;
  }
  50%{
    transform:scale(1.1);
    opacity: 1;
  }
  80%{
    transform:scale(0.9);
    opacity: 1;
  }
  100%{
    transform:scale(1);
    opacity: 1;
  }
`;

const FullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  display: flex;
  color: ${({theme}) => theme.text_light};
  background-color: ${({theme}) => theme.flow_status_header_bg_color};
  animation: ${fadeIn} 0.3s ease-out;
  
  &.fadeOut {
    animation: ${fadeIn} 0.3s 1.5s ease-out reverse forwards;
  }
`;

const InnerContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 25ch;
  align-items: center;

  h2 {
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    text-align: center;
    margin: 8px 0;
    opacity: 0;
    animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
  }

  h3 {
    opacity: 0;
    animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
    margin: -5px 0 4px 0;
    line-height: 25px;
  }
`;

const OuterContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  color: ${({theme}) => theme.primary};
  animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
`;

const InnerContainerLong = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  color: ${({theme}) => theme.primary};

  h2 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: ${({theme}) => theme.primary};
    margin: 16px 0;
    opacity: 0;
    animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
  }
`;

const RefundDetailContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  color: ${({theme}) => theme.primary};

  h2 {
    width: 100%;
    font-size: 17px;
    font-weight: 600;
    color: #094553;
    margin: 8px 0;
    opacity: 0;
    animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
  }

  h3 {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    color: #094553;
    margin: 12px 0;
    opacity: 0;
    animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
  }

  svg {
    margin: 0 0 16px;
  }
`;
const StrokeDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    p {
      font-size: 15px;
      font-weight: 600;
      color: #527B85;
      margin: 12px 0;
      opacity: 0;
      animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
    }
    b {
      font-size: 15px;
      font-weight: 600;
      color: #404040;
      text-decoration: line-through;
      margin: 12px 0;
      opacity: 0;
      animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
    }
    `;
  
const CleanDetails = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    p {
      font-size: 15px;
      font-weight: 600;
      margin: 12px 0;
      opacity: 0;
      animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
    }
    b {
      font-size: 20px;
      font-weight: 600;
      margin: 12px 0;
      opacity: 0;
      animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
    }
    `;

const FinalButton = styled.button`
    align-self: center;
    width: 100%;
    height: 42px; 
    align-items: center; 
    border-radius: 20px; 
    border: none;
    display: flex; 
    justify-content: center;
    color: #FFF;
    background: #094553;
    font-size: 17px;
    font-weight: 600;
    margin: 20px; 
    opacity: 0;
    animation: ${fadeIn} 0.3s 0.3s ease-out forwards;
  `;

const IconContainer = styled.div`
  opacity: 0;
  padding: 8px;
  animation: ${bounce} 0.5s 0.3s ease-in-out forwards;

  label {
    scale: 1.8;
  }
`;

const Separator = styled.hr`
  width: calc(100% + 32px);
  border: none;
  border-top: 2px solid ${({theme}) => theme.gray_20};
  margin: 16px -16px;
`;

const SolidSeparator = styled.hr`
  border: none;
  border-top: 2px solid ${({theme}) => theme.gray_20};
  margin: 0;
`;

const DashedSeparator = styled.hr`
  border: none;
  border-top: 2px dashed ${({theme}) => theme.gray_20};
  margin: 0;
`;

const TicketInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

const RoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: ${({theme}) => theme.background};
  border-radius: ${({theme}) => `${theme.radius_lg} ${theme.radius_lg} 0 0`};
  padding: 16px 24px 0px;
  box-shadow: ${({theme}) => theme.shadow_lg};
  position: sticky;
  bottom: 0;
  left: 0;
  width: 90vw;

  footer {
    margin: 0 -24px;
  }
`;

const SectionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 24px 0;
`;

const FooterContainer = styled.div`
  width: 100%;
  height: 24px;
`;

type List = {
  isExpanded: boolean
}

const ListWrapper = styled.div<List>`
  max-height: ${({isExpanded}) => isExpanded ? '300px' : '52px'};
  overflow: hidden;
  transition: 0.3s ease-in-out;
`

const ListToggle = styled.span<List>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: ${({theme}) => theme.font_xs};
  line-height: 1em;
  color: ${({theme}) => theme.gray_70};
  margin-bottom: 8px;

  svg {
    rotate: ${({isExpanded}) => isExpanded ? '-180deg' : '0deg'};
    transition: rotate 0.3s ease-in-out;
  }
`;

const PaymentList = styled.div<List>`
  max-height: ${({isExpanded}) => isExpanded ? '100%' : '52px'};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  div {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const PaymentBadge = styled.span`
  width: fit-content;
  color: ${({theme}) => theme.yumminn_blue};
  background-color: ${({theme}) => theme.yumminn_20};
  border-radius: ${({theme}) => theme.radius_xs};
  padding: 4px;
  font-size: ${({theme}) => theme.font_xs};
  line-height: ${({theme}) => theme.font_sm};
  font-weight: 600;
  letter-spacing: -0.5px;
  margin: 0;
`;

const WarningBox = styled.div`
  font-size: ${({theme}) => theme.font_xs};
  line-height: 1.3;
  text-align: center;
  color: ${({theme}) => theme.text};
  background-color: ${({theme}) => theme.cash_40};
  border-radius: ${({theme}) => theme.radius_lg};
  padding: 16px;
  position: relative;
  margin-top: 16px;

  b {
    font-weight: 600;
  }
  
  &::before {
    position: absolute;
    top: -10px;
    left: 50%;
    rotate: 45deg;
    content: '';
    height: 20px;
    width: 20px;
    background-color: inherit;
    border-radius: ${({theme}) => theme.radius_xs} 0 0 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type ButtonInnerProps = {
  rightBadge?: string; 
  rightCoin?: string;
  zIndex?: string;
}

const ButtonInner = styled.span<ButtonInnerProps>`
  width: 100%;
  position: relative;
 

  svg {
    position: absolute;
    right: ${({rightCoin}) => rightCoin ? rightCoin : "16px"};
    z-index: ${({zIndex}) => zIndex ? zIndex : ""};
  }
  
  & > div {
    position: absolute;
    top: -20px;
    right: ${({rightBadge}) => rightBadge ? rightBadge : "-24px"} ;
    max-height: 24px;
    box-sizing: border-box;
  }
`;

const TakeawayOrDeliveryCard = styled.div`
  font-size: ${({theme}) => theme.font_sm};
  padding: 10px 24px;
  line-height: 22px;

  p{

    & > span {
      & > span.google_maps {
        font-weight: 700;
        text-decoration: underline; 
        margin-left: 2px;
      }

      & > span:not([class]) {
        text-decoration: none !important;
        margin-left: 5px;
      }
  
        
    }

  }
`;

const IconContainer2 = styled.div`
  opacity: 0;
  padding: 8px;
  animation: ${bounce} 0.5s 0.3s ease-in-out forwards;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
  }

  p {
    font-size: 60px;
    margin: 0;
  }
`;

const IconContainerRecharge = styled.div`
  opacity: 0;
  animation: ${bounce} 0.5s 0.3s ease-in-out forwards;
  margin-left: 6px;

  svg {
    margin: 0 auto;
  }

`;

const DeliveryEmailCard = styled.div`
  font-size: ${({theme}) => theme.font_md};
  font-weight: 600;
  padding: 10px;
  line-height: 22px;
  background-color: ${({theme}) => theme.background_message};
  margin: 0 8px;
  border-radius: 10px;
  color: ${({theme}) => theme.text};
`;

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.flow_status_header_bg_color};
  animation: ${fadeIn} 0.3s ease-out;
  
  &.fadeOut {
    animation: ${fadeIn} 0.3s 1.5s ease-out reverse forwards;
  }
`;

export {
  PaymentSuccessfulOuterContainer,
  RestaurantNameContainer,
  SuccessfullIconWrapper,
  SuccessfullSentenceWrapper,
  TableStatusWrapper,
  PendingToPayWrapper,
  PaymentsList,
  PaymentDone,
  LoginBoxReminder,
  LoginMessageWrapper,
  LoginButtonWrapper,
  LoginButton,
  PaymentSuccessfulText,
  ShowButton,
  OrderAndPayCounterSuccessful,
  PointsBannerContainer,
  ReviewContainer,
  FullScreenContainer,
  InnerContainer,
  IconContainer,
  Separator,
  SolidSeparator,
  DashedSeparator,
  DivCounter,
  IDGroupContainer,
  TicketInner,
  RoadmapContainer,
  ListWrapper,
  ListToggle,
  PaymentList,
  PaymentBadge,
  InnerContainerLong,
  OuterContainer,
  RefundDetailContainer,
  StrokeDetails,
  CleanDetails,
  FinalButton,
  FooterContainer,
  SectionContainer,
  WarningBox,
  ButtonWrapper,
  ButtonInner,
  TakeawayOrDeliveryCard,
  DeliveryEmailCard,
  IconContainer2,
  LoadingContainer,
  IconContainerRecharge
};

