export const blue_linear = {
  text: '#F1FFF9',
  text_a80: '#F1FFF9CC',
  text_a50: '#F1FFF980',
  text_a30: '#F1FFF94D',
  text_a20: '#F1FFF933',
  text_a10: '#F1FFF91A',
  text_light: '#F1FFF9',
  text_dark: '#212529',
  text_credit_banner: '#404040',
  
  background_sunken: '#02313C',
  background: '#094553',
  background_rised: '#1C6070',
  background_contrast: 'var(--background_sunken)',
  background_lighter: '#094553',
  background_border: 'transparent',
  background_message: '#FAFAFA',

  backdrop_color: '#00000099',
  dark_overlay: '#00000099',

  button_border_neutral: '#FFFFFF',

  card_bg: 'var(--background_rised)',
  card_bg_selected: 'var(--tertiary_50)',
  card_text_selected: 'var(--background_sunken)',
  card_border: 'var(--border_xs)',
  card_border_color: '#DFFFF1',

  chip_bg_color: 'var(--background_rised)',
  chip_text_color: 'var(--text)',
  chip_icon_color: 'var(--text)',
  chip_border_color: 'var(--text)',
  chip_bg_color_selected: 'var(--text)',
  chip_text_color_selected: 'var(--background)',
  chip_icon_color_selected: '', // defined in component
  chip_border_color_selected: 'var(--text)',
  
  drawer_bg: 'var(--background_rised)',
  drawer_border: 'var(--border_sm) var(--tertiary_50)',
  
  header_bg: '#033642',
  header_counter_bg: 'var(--background_sunken)',
  header_counter_border: 'var(--background)',
  
  category_heading_text: 'var(--primary)',
  category_bar_bg: 'var(--header_bg)',
  category_bar_bg_variant: 'var(--background)',
  category_button_bg: 'var(--background)',
  category_button_text: 'var(--primary)',
  category_button_bg_selected: 'var(--primary)',
  category_button_text_selected: 'var(--background)',
  category_button_bg_variant: 'var(--background)',
  category_button_text_variant: 'var(--primary)',
  category_button_border_variant: 'var(--gray_65)',
  category_button_bg_selected_variant: 'var(--primary)',
  category_button_text_selected_variant: 'var(--background)',
  category_button_border_selected_variant: 'var(--primary)',
  category_button_bg_expanded_variant: 'var(--text)',
  category_button_text_expanded_variant: 'var(--background)',
  category_button_border_expanded_variant: 'var(--text)',
  category_slider_bg: 'var(--primary)',

  input_bg: 'var(--background_rised)',
  input_border: '#DFFFF1',

  payment_card_icon_selected: 'var(--text_dark)',
  payment_logos_filter: 'saturate(0) brightness(2)',
  
  img_placeholder_bg: 'var(--text_a50)',
  img_placeholder_bg_selected: 'var(--text_a50)',
  
  ticket_header_bg: 'var(--header_bg)',
  
  badge_qr_color: 'var(--text)',
  badge_qr_bg: 'var(--background_rised)',
  
  qr_fg_color: 'var(--background_sunken)',
  qr_bg_color: '#DFFFF1',

  wifi_text_color: '#FFFFFF',

  danger: '#E57070',
  danger_10: '#FCEBE7',
  danger_20: '#FFEAE5',
  danger_70: '#662B26',

  warning: '#FFDE85',
  warning_20: '#FFFBEF',
  warning_40: '#FFF3D1',
  warning_50: '#FFB88C',
  warning_70: '#A18948',

  notification: '#FF4D4D',

  gray_05: '#767676',
  gray_10: '#696969',
  gray_20: '#6C6C6C',
  gray_30: '#505050',
  gray_40: '#444444',
  gray_45: '#A7A7A7',
  gray_50: '#999999',
  gray_60: '#767676',
  gray_65: '#696969',
  gray_70: '#5D6266',
  gray_80: '#505050',
  gray_90: '#444444',

  greenish: '#5BB994',

  point_gradient: 'linear-gradient(68.74deg, #5FB894 -40.86%, #C1EBDA 56.81%)',
  point_gradient_70: 'linear-gradient(302.62deg, #9FE6C6 17.65%, #68CFA5 56.24%, #5FB894 90.2%)',
  point_gradient_40: 'linear-gradient(302.62deg, #5FB894 -141.32%, #C1EBDA -31.9%, #EFF8F4 75.88%)',
  cash_gradient: 'linear-gradient(291.32deg, #FDDF8A -1.95%, #FFF1C9 66.4%)',
  cash_gradient_70: 'linear-gradient(291.32deg, #F8B90C 0%, #FEDE80 100%)',
  cash_gradient_40: 'linear-gradient(21.32deg, #FDDF8A -40.86%, #FFF1C9 56.81%)',
  credit_banner: '#DFFFDF',
  switch_credit_banner: '#DFFFDF',
  switch_loyalty: 'var(--cash_gradient_40)',
  bg_switch_credit_banner: '#434343',
  border_switch_credit_banner: '#B4E5B4',
  bg_switch_loyalty: '#434343',
  bg_credit_banner: 'rgba(245, 255, 245, 0.4)',
  border_switch_loyalty: '#F2E3BB',
  bg_loyalty_banner: 'rgba(255, 246, 221, 0.4)',
  switch_disabled: '#F6F6F6',
  switch_disabled_text: '#404040c7',

  photo_overlay: '0deg, #0003, #000A',
  cover_overlay: '0deg, #002E3D44, #00485E22',

  border_xs: '1px solid',
  border_sm: '2px solid',
  border_md: '2px solid',
  
  flow_status_header_bg_color: "#00485E",
  flow_status_bg_color: "#03607C",

  square_icon_shadow_color: 'var(--text)',
};