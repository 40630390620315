import React from "react";

import { IButton } from "./interfaces";
import { ButtonSize, ButtonType, ButtonVariant, ButtonLineHeight } from "./enums";
import styled, { keyframes } from "styled-components";
import { getTextColor } from "../../shared/utils/colors";

export const ButtonComponent = ({
  title,
  type = ButtonType.Button,
  size = ButtonSize.Medium,
  variant = ButtonVariant.Primary,
  lineHeight = ButtonLineHeight.Small,
  leftIcon,
  rightIcon,
  color,
  style,
  className = '',
  width,
  height,
  fontWeight,
  loading = false, // disables html button
  disabled = false, // disables html button
  $disabled = false, // just renders disabled styles
  shadow = false,
  alignSelf = 'center',
  handleClick,
  disabledClick,
}: IButton): JSX.Element => {
  const onClick: React.MouseEventHandler = (e) => {
    if (disabled || loading) return;

    if ($disabled && disabledClick) {
      disabledClick(e)
      return;
    }
    
    handleClick && handleClick(e);
  };

  if (variant === 'link') return (
    <Link
      onClick={onClick}
      disabled={disabled || loading}
      size={size}
      alignSelf={alignSelf}
      style={style}
      className={className}
    >
      {leftIcon}
      {title}
      {rightIcon}
    </Link>
  );

  const components = {
    primary: PrimaryButton,
    secondary: SecondaryButton,
    neutral: NeutralButton,
  };

  const ButtonComponent = components[variant as keyof typeof components] || BaseButton;

  return (
    <ButtonComponent
      type={type as any}
      onClick={onClick}
      lineHeight={lineHeight}
      color={color}
      style={style}
      width={width}
      size={size}
      fontWeight={fontWeight}
      shadow={shadow}
      disabled={disabled}
      $disabled={disabled || $disabled}
      height={height}
      className={`${loading ? 'loading' : ''} ${className}`}
    >
      {loading && <LoadingItem className='loader'/>}
      {leftIcon}
      {typeof title === 'string' ? <span>{title}</span> : title}
      {rightIcon}
    </ButtonComponent>
  );
};

const BaseButton = styled.button<Partial<IButton>>`
  font-family: ${({theme}) => theme.typography};
  font-size: ${({size, theme}) => size || theme.font_md};
  font-weight: ${({fontWeight}) => fontWeight || 600};
  line-height: ${({lineHeight}) => lineHeight};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  align-self: ${({alignSelf}) => alignSelf};
  color: ${({theme}) => theme.text};
  background-color: ${({theme}) => theme.background};
  border: ${({theme}) => `${theme.border_xs} ${theme.text}`};
  border-radius: ${({theme}) => theme.radius_round};
  width: ${({width}) => width || '100%'};
  height: ${({height}) => height || '44px'};
  box-shadow: ${({$disabled, shadow, theme}) => $disabled || !shadow ? 'none' : theme.shadow_sm};
  filter: grayscale(${({$disabled}) => $disabled ? 0.8 : 0}) ;
  position: relative;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }

  > svg {
    flex-shrink: 0;
    height: 1.25em;
    width: 1.25em;
  }

  &.loading *:not(.loader) {
    opacity: 0;
  }

  &::after {
    content: '';
    background-color: ${({theme}) => theme.background};
    border: ${({theme}) => `${theme.border_sm} ${theme.background}`};
    border-radius: ${({theme}) => theme.radius_round};
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: ${({$disabled}) => $disabled ? 0.5 : 0};
    pointer-events: none;
  }

  &:has(> svg) {
    &:has(span:last-child) > span::after {
      content: '';
      height: 1.25em;
      width: 1.25em;
    }

    &:has(span:first-child) > span::before {
      content: '';
      height: 1.25em;
      width: 1.25em;
    }
  }
`;

const PrimaryButton = styled(BaseButton)`
  color: ${({color, theme}) => color ? getTextColor(color) : getTextColor(theme.primary)};
  background-color: ${({color, theme}) => color || theme.primary};
  border-color: ${({color, theme}) => color ? getTextColor(color) : getTextColor(theme.primary)}40;
`;

const SecondaryButton = styled(BaseButton)`
  color: ${({color, theme}) => color || theme.primary};
  background-color: ${({theme}) => theme.background};
  border-color: ${({color, theme}) => color || theme.primary};
`;

const NeutralButton = styled(BaseButton)`
  border-color: ${({theme}) => theme.button_border_neutral};
  box-shadow: ${({$disabled, theme}) => $disabled ? 'none' : theme.shadow_xs};
`;

const Link = styled.button<LinkProps>`
  display: flex;
  align-items: center;
  gap: 0.25em;
  font-size: ${({size,  theme}) => size === ButtonSize.Small ? theme.font_xs : theme.font_sm};
  font-weight: ${({size}) => size === ButtonSize.Small ? 600 : 700};
  text-decoration: underline;
  color: ${({theme}) => theme.text};
  background-color: transparent;
  border: none;
  padding: 8px;
  margin: -8px;
  align-self: ${({alignSelf}) => alignSelf};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > svg {
    height: 1.125em;
    width: 1.125em;
  }
`;

type LinkProps = {
  size: ButtonSize
  alignSelf: string
};

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingItem = styled.div`
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: ${({theme}) => theme.radius_round};
  border: 4px solid;
  border-color: #0004;
  border-top-color: currentColor;
  animation: ${spin} 1s linear infinite;
`;